/***** NOTIFICATION ****/
.notifications-tc {
    position: fixed;
    top: 20px;
    left: 50%;
    z-index: 9000;
    width: 400px;
    margin-left: -200px;
}

.notification {
    position: relative;
    padding: 15px;
    margin-bottom: 5px;
    line-height: 1.4;
    border-radius: 70px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, .25);
}

.notification a {
    text-decoration: underline;
}

.notification-error {
    color: #fff;
    background-color: #e53935;
}

.notification-success {
    color: #fff;
    background-color: #4caf50;
}

.notification-warning {
    background-color: #ef6c00;
}

.notification-info {
    color: #fff;
    background-color: #298fbf;
}

.notification-hidden {
    display: none;
}

.notification-visible {
    display: block;
}

.notification-message {
    padding-right: 35px;
}

.notification-dismiss {
    position: absolute;
    top: 5px;
    right: 15px;
    font-size: 25px;
    text-align: center;
    cursor: pointer;
}

.notification-content > div {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #fff;
}

.notification-content > div:first-child {
    padding-top: 0;
    margin-top: 0;
    border-top: none;
}
