.login-main-container {
    background: url(../../img/login_screen.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
}

.login-content {
    padding: 65px 15px;
    max-width: 960px;
}

.login-frame {
    box-shadow: 0 0 0 1px #e5e5e5;
    padding: 15px 25px 20px 25px;
    background-color: #ffffff;
}

.login-frame-title {
    padding: 0px 0px 8px 0px;
    border-bottom: solid 1px #e5e5e5;
}

.login-frame-form {
    padding: 20px 0px 15px 0px;
}