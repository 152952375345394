/*** MOUSE **/
.pointer {
    cursor: pointer;
}

.main {
    /* padding-bottom: 50px; */
}

/***************************** TITLES **********************/
.title1, .title2, .title3 {
    display: block;
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 500;
    line-height: 1.2;
    color: #1aaa55;
}

.title1 {
    font-size: 36px;
}

.title2 {
    font-size: 24px;
}

.title3 {
    font-size: 18px;
    margin-top: 10px;
}

.title4 {
    font-size: 16px;
    margin-top: 10px;
}

/***************************** TEXT ***********************/
.text1 {
    font-weight: bold;
    font-size: 15px;
}

/***************************** FORM ***********************/
.error {
    color: #b94a48;
}

.form-group {
    margin-bottom: 25px !important;
}

.control-label {
    color: #666666;
}

.form-control {
    color: #666666 !important;
    border-radius: 0px !important;
}